import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import LogoNoTextSVG from './LogoNoTextSVG';

const navy = 'rgba(19, 20, 61, 1)';

export const loadingAnimation = keyframes`
  0% {
    background-position: 100% 100%;
  }

  100% {
    background-position: 0 0;
  }
`;

const ColorSplash = styled.div`
  width: 100%;
  height: 5px;
  background-image: linear-gradient(
    to right,
    #20536b 0%,
    #13143d 50%,
    #20536b 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  background-size: 1000% auto;
  animation: ${loadingAnimation};
  animation-duration: ${props => props.loading && '1.5s'};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const StyledEmailFormContainer = styled.div`
  width: 40vw;
  min-width: 280px;
  max-width: 500px;
  background: rgba(236, 237, 247, 0.95);
  border-radius: 12px;
  position: relative;
  padding: 2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgba(19, 20, 61, 1);
  overflow: hidden;
  text-align: center;
  @media (max-width: 700px) {
    padding: 1rem;
    svg {
      display: none;
    }
  }
  .firstAndLast {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
  h4 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: calc(0.7vh + 0.7vw);
  }
  h4.barrioLogan {
    font-size: calc(1.1vh + 1.1vw);
  }
  span.days {
    color: rgba(168, 8, 8, 1);
  }
  p {
    opacity: 0.9;
    letter-spacing: 1px;
    line-height: 1.3rem;
  }
  p.unsubscribe {
    font-size: 0.8rem;
    opacity: 0.8;
    margin: -3px 0 0px 0;
    text-align: right;
  }
  svg {
    width: 20%;
    margin: 4%;
  }
  fieldset {
    border: none;
    label {
      width: 100%;
    }
    input {
      border-radius: 6px;
      height: 40px;
      font-size: 1.2rem;
      width: 100%;
      margin: 0.5rem 0;
      border-color: transparent;
      border-width: 1px;
      border-style: solid;
      text-indent: 0.5rem;
      outline: none;
    }
    input.overTwentyOne {
      border-radius: 20px;
      background: transparent;
      border: 2px solid red;
    }
    button {
      height: 40px;
      border: none;
      margin: 0.5rem 0;
      border-radius: 2px;
      background: rgba(19, 20, 61, 0.7);
      color: white;
      font-weight: 100;
      letter-spacing: 3px;
      padding: 0;
      width: 100%;
      cursor: pointer;
      &:disabled {
        opacity: 0.4;
        cursor: none;
      }
    }
  }
`;
const CheckboxContainer = styled.div`
  width: 100%;
  text-align: left;
  font-size: 1rem;
  label {
    display: flex;
    align-items: center;
  }
`;

const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <label>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} onClick={() => props.onChange}>
        <p>✔</p>
      </StyledCheckbox>
      I am Over 21 Years Old
    </label>
  </CheckboxContainer>
);

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  /* Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually */
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 3px;
  border: ${navy} 2px solid;
  margin-right: 0.5rem;
  position: relative;
  p {
    color: green;
    font-size: 2.5rem;
    position: absolute;
    margin: 0;
    bottom: 2px;
    left: -5%;
    opacity: ${props => (props.checked ? 1 : 0)};
    transition: all 0.4s;
  }
`;

const EmailForm = () => {
  const [email, setEmail] = useState('');
  const [FNAME, setFNAME] = useState('');
  const [LNAME, setLNAME] = useState('');
  const [over21, setOver21] = useState(false);
  const [bot, setBot] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  return (
    <StyledEmailFormContainer>
      <ColorSplash loading={isSubmitting} />
      <LogoNoTextSVG />
      <p>Sign up for the latest info and best deals.</p>
      {!submitted && (
        <form
          id="newsletter"
          noValidate
          onSubmit={async e => {
            e.preventDefault();
            if (!over21) {
              window.alert(
                'You must be at least 21 years old to subscribe to our newsletter.'
              );
              return;
            }
            setIsSubmitting(true);
            if (bot) return;
            const res = await fetch('/.netlify/functions/subscribe', {
              method: 'post',
              body: JSON.stringify({
                email,
                merge_fields: {
                  FNAME,
                  LNAME,
                  MMERGE3: over21 ? 'Yes' : 'No',
                },
              }),
            });
            if (res) {
              setIsSubmitting(false);
              setSubmitted(true);
            }
          }}
        >
          <fieldset>
            <div className="emailAndSubmit">
              <div className="firstAndLast">
                <label>
                  <input
                    placeholder="First Name"
                    id="FNAME"
                    htmlFor="FNAME"
                    className="firstName"
                    required
                    autoFocus
                    type="text"
                    value={FNAME}
                    onChange={e => setFNAME(e.target.value)}
                  />
                </label>
                <label>
                  <input
                    placeholder="Last Name"
                    id="LNAME"
                    htmlFor="LNAME"
                    className="lastName"
                    required
                    type="text"
                    value={LNAME}
                    onChange={e => setLNAME(e.target.value)}
                  />
                </label>
              </div>
              <label>
                <input
                  placeholder="Enter your email"
                  id="newsLetterEmailSignup"
                  htmlFor="newsLetterEmailSignup"
                  className="email"
                  required
                  autoFocus
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </label>
              <Checkbox
                id="over21"
                htmlFor="overTwentyOne"
                className="overTwentyOne"
                required
                type="checkbox"
                checked={over21}
                onChange={() => setOver21(!over21)}
              />
              <button
                disabled={!email || !FNAME || !LNAME || isSubmitting}
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
              >
                {isSubmitting ? 'Submitting' : 'Keep me in the Loop'}
              </button>
            </div>
            <p className="unsubscribe">*You can unsubscribe at anytime</p>
          </fieldset>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
            aria-label="Please leave the following three fields empty"
          >
            <label htmlFor="b_name">
              Name:
              <input
                type="text"
                name="b_name"
                tabIndex="-1"
                value=""
                placeholder="Freddie"
                id="b_name"
                onChange={() => setBot(true)}
              />
            </label>
            <label htmlFor="b_email">
              Email:
              <input
                type="email"
                name="b_email"
                tabIndex="-1"
                value=""
                placeholder="youremail@gmail.com"
                id="b_email"
                onChange={() => setBot(true)}
              />
            </label>
            <label htmlFor="b_comment">
              Comment:
              <textarea
                name="b_comment"
                tabIndex="-1"
                placeholder="Please comment"
                id="b_comment"
                onChange={() => setBot(true)}
              />
            </label>
          </div>
        </form>
      )}
      {submitted && <h2>Thanks for signing up, see you soon!</h2>}
    </StyledEmailFormContainer>
  );
};

const Landing = () => {
  return <EmailForm />;
};

export default Landing;
