import React from 'react';
import styled from 'styled-components';

const StyledFadeInSection = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => `translatey(${props.isVisible ? '-2vw' : '16vh'})`};
  transition: opacity 1s cubic-bezier(1, 0.12, 1, 1), transform 0.5s linear;
  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    * {
      transition-duration: all 0.001ms !important;
    }
  }
`;

const FadeInSection = props => {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.unobserve(domRef.current);
          }
        });
      },
      // IntersectObserver Options
      { threshold: 0.3 }
    );
    observer.observe(domRef.current);
    return () => observer.unobserve(domRef.current);
  }, []);
  return (
    <StyledFadeInSection isVisible={isVisible} ref={domRef}>
      {props.children}
    </StyledFadeInSection>
  );
};

export default FadeInSection;
