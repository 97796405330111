import React, { useState } from 'react';
import styled from 'styled-components';
import DinnerOne from './backgrounds/DinnerOne';
import LibertyCallProduction from './backgrounds/LibertyCallProduction';
import Cocktail from './backgrounds/Cocktail';
import ExplorersCollectionBottles from './backgrounds/ExplorersCollectionBottles';
import PouringBlueRidge from './backgrounds/PouringBlueRidge';
import SDMap from './backgrounds/SDMap';
import HomeFooter from './HomeFooter';
import { SplitLayout } from './styles/Layout';
import FadeInSection from './FadeInSection';
import TransitionLink from './TransitionLink';
import WipeTransition from './WipeTransition';
import { TransitionPortal } from 'gatsby-plugin-transition-link';
// Only used for TemporaryStyledLink below. Delete once this isn't needed...
import { SwipeInFromRight, SwipeInFromLeft } from './TransitionLink';

const heightRatio = 1;

const TemporaryStyledLink = styled.a`
  font-size: 3rem;
  color: ${props => props.theme.lightgrayblue()};
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  border-bottom: 3px solid ${props => props.theme.lightgrayblue()};
  z-index: 4;
  &:hover,
  &:focus {
    color: ${props => props.theme.navy()};
    padding-left: 30px;
    ::before {
      content: '';
      z-index: -1;
      width: 100vw;
      height: 1.4em;
      position: absolute;
      animation: ${props => (props.left ? SwipeInFromLeft : SwipeInFromRight)}
        0.3s ease-in-out;
      top: 0;
      left: ${props => !props.left && '-12px'};
      right: ${props => props.left && '-12px'};
      /* Create angle */
      transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      -webkit-transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      -moz-transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      -o-transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      background: ${props => props.theme.red()};
    }
  }
  transition: all 0.3s;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    font-size: 2rem;
  }
`;

const OneHalf = styled.div`
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;
  background: ${props => props.background};
  height: ${props =>
    props.half ? `${heightRatio * 100}vh` : `${heightRatio * 200}vh`};
  max-height: ${props =>
    props.half ? `${heightRatio * 100}vh` : `${heightRatio * 200}vh`};
  padding: ${props =>
    props.right ? '0 12vw 0 5vw' : props.left && '0 5vw 0 12vw'};
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    padding: 0 14vw 0 5vw;
  }
`;

const HeaderWrapper = styled.div`
  /* height: calc(${heightRatio * 200}vh + 3vw); */
  /* height: 115vh; */
  z-index: 0;
  .inner {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    top: 0;
    background: ${props => props.background || 'transparent'};
    /* position: sticky; */
    position: absolute;
    bottom: 0;
    z-index: 1;
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    .inner {
      position: absolute;
      top: 5vh;
    }
  }
`;

const MainHeaderText = styled.div`
  line-height: 4vw;
  text-align: left;
  margin: 0 auto 2vh auto;
  color: ${props => props.theme.white()};
  display: grid;
  grid-template-columns: 1fr 5vw 1fr;
  h1 {
    margin: 0;
    font-size: 40px;
  }
  .distillery {
    letter-spacing: 1.6vw;
    text-align: right;
  }
  .restaurant {
    margin-left: calc(2.5vw - 3px);
    letter-spacing: 1.6vw;
  }
  .and {
    font-size: 8vw;
    font-weight: 700;
    color: ${props => props.theme.red()};
  }
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-gap: 50px;
    h1 {
      font-size: 10vw;
    }
    .and {
      font-size: 20vw;
    }
  }
`;

const Paragraph = styled.p`
  text-align: left;
  font-size: calc(2.2vh);
  line-height: 5vh;
  color: ${props => props.color || props.theme.white()};
  opacity: 0.95;
  font-weight: 200;
  span.bold {
    color: ${props => props.theme.lightgrayblue()};
    font-weight: 600;
  }
`;

const MenuSection = ({ beginTransition }) => {
  return (
    <DinnerOne section={1} overlayColor="darkgray" overlayOpacity="0.8" fixed>
      <OneHalf right>
        <div>
          <FadeInSection>
            <Paragraph>
              <span className="bold">
                We know everyone should have the right to Life, Liberty, and The
                Pursuit of Happiness.
              </span>{' '}
              And we believe happiness is a mouthwatering meal and a delicious
              cocktail with family and friends. At Liberty Call, we have
              combined the best ingredients, both edible and drinkable, to
              create a menu and cocktail list that will satisfy all San Diego
              locals, our neighbors, and guests.
            </Paragraph>
            <TransitionLink
              left
              onClick={beginTransition}
              to="/menu"
              exit={{
                length: 0.8,
              }}
              entry={{
                delay: 0.8,
                length: 0.4,
              }}
            >
              The Menu
            </TransitionLink>
          </FadeInSection>
        </div>
      </OneHalf>
    </DinnerOne>
  );
};

const SpiritSection = ({ beginTransition }) => (
  <PouringBlueRidge
    section={2}
    overlayColor="darkgray"
    overlayOpacity="0.8"
    fixed
    backgroundPosition="20% 50%"
  >
    <OneHalf left>
      <div>
        <FadeInSection>
          <Paragraph>
            <span className="bold">
              It was all a dream, and it started with a bottle of moonshine.
            </span>{' '}
            Before the little distillery in Spring Valley, long before the
            barrel warehouse, and way before the restaurant, Liberty Call was
            just a dream to our founders. A dream that their passion for
            creating spirits they liked to drink, could be shared with everyone.
            This was all realized back in 2013 when our distillery was
            established. And these are our spirits we'd like to share.
          </Paragraph>
          <TransitionLink
            left
            onClick={beginTransition}
            to="/spirits"
            exit={{
              length: 0.8,
            }}
            entry={{
              delay: 0.8,
              length: 0.4,
            }}
          >
            Our Spirits
          </TransitionLink>
        </FadeInSection>
      </div>
    </OneHalf>
  </PouringBlueRidge>
);

const CocktailSection = ({ beginTransition }) => (
  <Cocktail
    section={2}
    overlayColor="darkgray"
    overlayOpacity="0.8"
    fixed
    backgroundPosition="75% 75%"
  >
    <OneHalf right>
      <div>
        <FadeInSection>
          <Paragraph>
            <span className="bold">
              The cocktail revolution is alive and well in beautiful San Diego,
            </span>{' '}
            a city perched near west coast waters and thriving with a passion
            for sport sailing, deep sea fishing, diving, and of course good time
            merriment. As premiere distillers, Liberty Call Distilling is master
            crafting spirits locally using quality ingredients indigenous to
            sunny California. Spirit lovers yearn for distinguished tastes that
            stand out from the rest and we have answered the call.
          </Paragraph>
          <TransitionLink
            left
            onClick={beginTransition}
            to="/cocktails"
            exit={{
              length: 0.8,
            }}
            entry={{
              delay: 0.8,
              length: 0.4,
            }}
          >
            Cocktails
          </TransitionLink>
        </FadeInSection>
      </div>
    </OneHalf>
  </Cocktail>
);

const BuySection = ({ beginTransition }) => (
  <SDMap section={3} overlayColor="navy" overlayOpacity="0.975" fixed>
    <OneHalf left>
      <FadeInSection>
        <div>
          <h3 style={{ fontSize: '5rem' }}>
            Get Liberty Call delivered right to your doorstep
          </h3>
          <TemporaryStyledLink
            href="https://libertycall-103670.square.site/"
            target="_blank"
            rel="noopener"
            noreferrer
          >
            Buy a Bottle
          </TemporaryStyledLink>
          {/* <h4 style={{ marginTop: '4em', fontSize: '3rem' }}>
            Delivery and Takeout available from our Barrio Logan restaurant
          </h4> */}
          {/* <TemporaryStyledLink
            href="https://www.toasttab.com/liberty-call-distilling-1985-national-ave-1131/v3"
            target="_blank"
            rel="noopener"
            noreferrer
          >
            Get it Local
          </TemporaryStyledLink> */}
        </div>
      </FadeInSection>
    </OneHalf>
  </SDMap>
);

const StorySection = ({ beginTransition }) => (
  <LibertyCallProduction
    section={3}
    overlayColor="darkgray"
    overlayOpacity="0.8"
    fixed
  >
    <OneHalf right>
      <div>
        <FadeInSection>
          <Paragraph>
            <span className="bold">We all begin somewhere.</span> From late
            night, whiskey inspired dreams, to our first distillery and beyond.
            We are proud of our humble beginnings, and to be a leader in
            California's growing distillery scene.
          </Paragraph>
          <TransitionLink
            left
            onClick={beginTransition}
            to="/our-story"
            exit={{
              length: 0.8,
            }}
            entry={{
              delay: 0.8,
              length: 0.4,
            }}
          >
            Our Story
          </TransitionLink>
        </FadeInSection>
      </div>
    </OneHalf>
  </LibertyCallProduction>
);

const FooterSection = ({ beginTransition }) => (
  <ExplorersCollectionBottles overlayColor="maroon" overlayOpacity="0.92" fixed>
    <OneHalf
      half
      section={4}
      // Align footer to bottom
      style={{ padding: 0, alignContent: 'end' }}
    >
      <HomeFooter />
    </OneHalf>
  </ExplorersCollectionBottles>
);

const TopBlankSection = () => (
  <ExplorersCollectionBottles
    overlayColor="darkgray"
    overlayOpacity="0.8"
    fixed
  >
    <OneHalf half section={1} style={{ padding: 0 }} />
  </ExplorersCollectionBottles>
);

const HomePageLayout = styled(SplitLayout)`
  .topBlankSection {
    grid-area: topBlankSection;
  }
  .spiritSection {
    grid-area: spiritSection;
  }
  .buySection {
    grid-area: buySection;
  }
  .footerSection {
    grid-area: footerSection;
  }
  .menuSection {
    grid-area: menuSection;
  }
  .cocktailSection {
    grid-area: cocktailSection;
  }
  .storySection {
    grid-area: storySection;
  }
  grid-template-areas:
    'topBlankSection menuSection'
    'spiritSection menuSection'
    'spiritSection cocktailSection'
    'buySection cocktailSection'
    'buySection storySection'
    'footerSection storySection';
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    grid-template-areas:
      'topBlankSection'
      'menuSection'
      'spiritSection'
      'cocktailSection'
      'buySection'
      'storySection'
      'footerSection';
    .footerSection {
      min-height: 165vh;
      height: 100%;
    }
  }
`;

const Home = () => {
  const [transitionFromSide, setTransitionFromSide] = useState();
  return (
    <>
      <HeaderWrapper>
        <div className="inner">
          <MainHeaderText>
            <h1 className="distillery">Distillery</h1>
            <h1 className="and">&</h1>
            <h1 className="restaurant"> Restaurant</h1>
          </MainHeaderText>
        </div>
      </HeaderWrapper>
      <HomePageLayout>
        <div className="topBlankSection">
          <TopBlankSection />
        </div>
        <div className="menuSection">
          <MenuSection beginTransition={() => setTransitionFromSide('right')} />
        </div>
        <div className="spiritSection">
          <SpiritSection
            beginTransition={() => setTransitionFromSide('left')}
          />
        </div>
        <div className="cocktailSection">
          <CocktailSection
            beginTransition={() => setTransitionFromSide('right')}
          />
        </div>
        <div className="buySection">
          <BuySection beginTransition={() => setTransitionFromSide('left')} />
        </div>
        <div className="storySection">
          <StorySection
            beginTransition={() => setTransitionFromSide('right')}
          />
        </div>
        <div className="footerSection">
          <FooterSection
            beginTransition={() => setTransitionFromSide('left')}
          />
        </div>
      </HomePageLayout>
      {transitionFromSide === 'right' && (
        <TransitionPortal>
          <WipeTransition animation="startFromHalfwayRightToLeft" />
        </TransitionPortal>
      )}
      {transitionFromSide === 'left' && (
        <TransitionPortal>
          <WipeTransition animation="startFromHalfwayLeftToRight" />
        </TransitionPortal>
      )}
    </>
  );
};

export default Home;
