import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import SimpleButton from './styles/SimpleButton';
import SimplerButton from './styles/SimplerButton';
import { FBsvg, IGsvg } from './SocialIcons';
import TermsAndConditions from './FunStuff/TermsAndConditions';
import PrivacyPolicy from './FunStuff/PrivacyPolicy';
import SignUp from './SignUp';
import Modal from './Modal';

const StyledContactForm = styled.form`
  display: block;
  text-align: left;
  .hidden {
    height: 0px;
    display: hidden;
    visibility: hidden;
  }

  label {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: left;
    row-gap: 8px;
    font-size: 0.6em;
    :focus-within {
      span.labelText {
        color: ${props => props.theme.colorThree};
        transition: color 0.4s;
      }
    }
  }

  input,
  textarea {
    width: 100%;
    background: transparent;
    border-color: white;
    border-style: solid;
    border-width: 0 0 3px 0;
    padding: 0;
    outline: none;
    font-size: 2em;
    color: white;
    border-radius: 0;
  }

  input {
    height: 30px;
  }

  textarea {
    width: 100%;
    height: 90px;
  }

  .buttonContainer {
    margin-top: 12px;
    text-align: right;
    button {
      background: transparent;
      border-color: white;
      border-radius: 2px;
      color: white;
      padding: 0 2vw;
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.maroon()};
        background: white;
      }
    }
  }
`;

const WhiteLogo = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "whiteLogo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp;
      return (
        <img
          style={{ width: '70%' }}
          src={imageData.fluid.src}
          alt="Liberty Call Distilling Logo"
        />
      );
    }}
  />
);

const ContactForm = () => {
  return (
    <StyledContactForm
      id="contact"
      name="contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <p className="hidden">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </p>
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>
          <span className="labelText">Name: </span>
          <input type="text" name="name" />
        </label>
      </p>
      <p>
        <label>
          <span className="labelText">Email: </span>
          <input type="email" name="email" />
        </label>
      </p>
      <p>
        <label>
          <span className="labelText">Message: </span>{' '}
          <textarea name="message" />
        </label>
      </p>
      <div className="buttonContainer">
        <SimpleButton style={{ borderRadius: '6px' }} type="submit">
          Send
        </SimpleButton>
      </div>
    </StyledContactForm>
  );
};

const StyledFooter = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem 2rem 2rem 2rem;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  .content {
    line-height: 1.5em;
    margin-right: 1vw;
    display: grid;
    grid-gap: 5vw;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'contact logo'
      'hours address';
    margin-bottom: 3vw;
  }
  .veryBottom {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    line-height: 1rem;
    align-items: center;
    text-align: center;
    a,
    p {
      letter-spacing: 2.5px;
    }
    a {
      margin: 0 15px;
    }
  }
  .logo {
    grid-area: logo;
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
  }
  .socialIcons {
    display: flex;
    justify-content: center;
    a {
      margin: 0;
    }
    svg {
      &:hover {
        fill: ${props => props.theme.navy()};
      }
      transition: fill 0.3s;
    }
  }
  .contact {
    grid-area: contact;
  }
  .hours {
    grid-area: hours;
  }
  .address {
    grid-area: address;
    text-align: center;
    align-content: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  h5 {
    font-weight: 600;
    letter-spacing: 5px;
    font-size: 1em;
    padding: '2px 10px';
  }
  .directions {
    background: transparent;
    border-color: white;
    border-radius: 2px;
    color: white;
    padding: 0 2vw;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.maroon()};
      background: white;
    }
  }
  table {
    text-transform: none;
    width: 100%;
    td.hrs {
      text-align: right;
    }
  }
  p {
    margin: 0;
    line-height: 1.8em;
  }
  p.drinkResponsibly {
    text-align: center;
    margin-top: 2vw;
    letter-spacing: 7px;
  }
  @media (max-width: 1000px) {
    .content {
      grid-template-areas:
        'contact contact'
        'hours address';
    }
    .logo {
      display: none;
    }
  }
  @media (max-width: 820px) {
    .content {
      grid-template-areas:
        'contact contact'
        'hours hours'
        'address address';
    }
  }
  @media (max-width: 700px) {
    .content {
      grid-template-areas:
        'contact logo'
        'hours address';
    }
    .logo {
      display: inherit;
    }
  }
  @media (max-width: 385px) {
    .content {
      grid-template-areas:
        'contact contact'
        'hours hours'
        'address address';
    }
    .logo {
      display: none;
    }
    .veryBottom {
      flex-direction: column;
      margin: 2rem 0;
    }
  }
  a {
    color: white;
    &:hover {
      color: ${props => props.theme.navy()};
    }
    transition: color 0.3s;
  }
`;

const Footer = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  return (
    <>
      <StyledFooter
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
        }}
      >
        <div className="content">
          <div className="contact">
            <h5>Drop Us A Line...</h5>
            <ContactForm />
          </div>
          <div className="logo">
            <WhiteLogo />
          </div>
          <div className="hours">
            <h5 style={{ margin: '0.5rem 0' }}>Hours</h5>
            <table>
              <tbody>
                <tr>
                  <td className="days">Mon - Fri</td>
                  <td className="hrs">11am - 9pm</td>
                </tr>
                <tr>
                  <td className="days">Sat</td>
                  <td className="hrs">11am - 9pm</td>
                </tr>
                <tr>
                  <td className="days">Sunday</td>
                  <td className="hrs">11pm - 8pm</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="address">
            <p>
              1985 National Ave - Suite 1131
              <br />
              San Diego, CA 92113
              <br />
              Restaurant: 619.432.1848
              <br />
              Corporate Office: 619.630.1240
              <br />
            </p>
            <SimpleButton
              onClick={() =>
                window.open(
                  'https://www.google.com/maps/place/Liberty+Call+Distilling/@32.6996263,-117.1473002,17z/data=!3m1!4b1!4m5!3m4!1s0x80d95a6f494131eb:0x62647e5abaf5a954!8m2!3d32.6996263!4d-117.1451115'
                )
              }
              className="directions"
            >
              Google Map
            </SimpleButton>
          </div>
        </div>
        <div className="veryBottom">
          <p>© 2021 | LIBERTY CALL DISTILLING CO</p>
          <SimplerButton
            color="white"
            onClick={() => setShowPrivacyPolicy(true)}
          >
            PRIVACY POLICY
          </SimplerButton>
          <SimplerButton
            color="white"
            onClick={() => setShowTermsAndConditions(true)}
          >
            TERMS OF USE
          </SimplerButton>
          <div className="socialIcons">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/LibertyCallDistillingCompany"
            >
              <FBsvg color="white" height="20px" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/libertycalldistilling/"
            >
              <IGsvg color="white" height="20px" />
            </a>
          </div>
        </div>
        <SimplerButton
          color="white"
          className="newsLetterLink"
          style={{ margin: '0 auto' }}
          onClick={() => setShowSignUpForm(true)}
        >
          Get the inside scoop!
        </SimplerButton>
        <p className="drinkResponsibly">Please Drink Responsibly</p>
      </StyledFooter>
      {showTermsAndConditions && (
        <TermsAndConditions
          handleClose={() => setShowTermsAndConditions(false)}
        />
      )}
      {showPrivacyPolicy && (
        <PrivacyPolicy handleClose={() => setShowPrivacyPolicy(false)} />
      )}
      <Modal
        open={showSignUpForm}
        handleClose={() => setShowSignUpForm(false)}
        colorTwo="transparent"
      >
        <SignUp />
      </Modal>
    </>
  );
};

export default Footer;
