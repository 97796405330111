import TransitionLink from 'gatsby-plugin-transition-link';
import styled, { keyframes } from 'styled-components';

export const SwipeInFromRight = keyframes`
0% {
  transform: translateX(100%);
}
100% {
  transform: translateX(0);
}
`;

export const SwipeInFromLeft = keyframes`
0% {
  transform: translateX(-100%);
}
100% {
  transform: translateX(0);
}
`;

const StyledTransitionLink = styled(TransitionLink)`
  font-size: 3rem;
  color: ${props => props.theme.lightgrayblue()};
  text-transform: uppercase;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  border-bottom: 3px solid ${props => props.theme.lightgrayblue()};
  z-index: 4;
  &:hover,
  &:focus {
    color: ${props => props.theme.navy()};
    padding-left: 30px;
    ::before {
      content: '';
      z-index: -1;
      width: 100vw;
      height: 1.4em;
      position: absolute;
      animation: ${props => (props.left ? SwipeInFromLeft : SwipeInFromRight)}
        0.3s ease-in-out;
      top: 0;
      left: ${props => !props.left && '-12px'};
      right: ${props => props.left && '-12px'};
      /* Create angle */
      transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      -webkit-transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      -moz-transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      -o-transform: skew(${props => (props.left ? '-20deg' : '20deg')});
      background: ${props => props.theme.red()};
    }
  }
  transition: all 0.3s;
  @media (max-width: ${props => props.theme.mobileBreakPoint}) {
    font-size: 2rem;
  }
`;

export default StyledTransitionLink;
