import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundSection from './Background';

const SDMap = ({ className, children, overlayColor, overlayOpacity }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "sdMap.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundSection
          className={className}
          imageData={imageData}
          children={children}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
        />
      );
    }}
  />
);

export default SDMap;
