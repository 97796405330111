import styled from 'styled-components';

const SVGIcon = styled.svg`
  height: ${props => props.height || '32px'};
  opacity: 0.85;
  cursor: pointer;
  margin: 1em;
  &:hover {
    opacity: 0.8;
  }
  fill: ${props => props.color || 'black'};
  transition: all 0.2s;
`;

export default SVGIcon;
