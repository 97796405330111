import styled from 'styled-components';
import SimpleButton from './SimpleButton';

const SimplerButton = styled(SimpleButton)`
  border-width: 0;
  color: ${props => props.color};
  font-size: 1em;
  &:hover {
    color: ${props => props.theme.navy()};
  }
`;

export default SimplerButton;
