import React from 'react';
import Modal from '../Modal';
import { FullScreenModalContent } from './PrivacyPolicy';

const TermsAndConditions = ({ handleClose }) => (
  <Modal colorOne="navy" open={true} handleClose={handleClose}>
    <FullScreenModalContent>
      <h2>Liberty Call Distilling Co's Terms of Service</h2>
      <p>
        1. Terms By accessing the website at libertycall.com, you are agreeing
        to be bound by these terms of service, all applicable laws and
        regulations, and agree that you are responsible for compliance with any
        applicable local laws. If you do not agree with any of these terms, you
        are prohibited from using or accessing this site. The materials
        contained in this website are protected by applicable copyright and
        trademark law.
        <br /> <br />
        2. Use License
        <br /> <br />
        Permission is granted to temporarily download one copy of the materials
        (information or software) on Liberty Call Distilling Co's website for
        personal, non-commercial transitory viewing only. This is the grant of a
        license, not a transfer of title, and under this license you may not:
        <br /> <br />
        i. modify or copy the materials;
        <br /> <br />
        ii. use the materials for any commercial purpose, or for any public
        display (commercial or non-commercial);
        <br /> <br />
        iii. attempt to decompile or reverse engineer any software contained on
        Liberty Call Distilling Co's website;
        <br /> <br />
        iv. remove any copyright or other proprietary notations from the
        materials; or
        <br /> <br />
        v. transfer the materials to another person or "mirror" the materials on
        any other server.
        <br /> <br />
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by Liberty Call Distilling Co at any
        time. Upon terminating your viewing of these materials or upon the
        termination of this license, you must destroy any downloaded materials
        in your possession whether in electronic or printed format.
        <br /> <br />
        3. Disclaimer
        <br /> <br />
        a. The materials on Liberty Call Distilling Co's website are provided on
        an 'as is' basis. Liberty Call Distilling Co makes no warranties,
        expressed or implied, and hereby disclaims and negates all other
        warranties including, without limitation, implied warranties or
        conditions of merchantability, fitness for a particular purpose, or
        non-infringement of intellectual property or other violation of rights.
        <br /> <br />
        b. Further, Liberty Call Distilling Co does not warrant or make any
        representations concerning the accuracy, likely results, or reliability
        of the use of the materials on its website or otherwise relating to such
        materials or on any sites linked to this site.
        <br /> <br />
        4. Limitations
        <br /> <br />
        In no event shall Liberty Call Distilling Co or its suppliers be liable
        for any damages (including, without limitation, damages for loss of data
        or profit, or due to business interruption) arising out of the use or
        inability to use the materials on Liberty Call Distilling Co's website,
        even if Liberty Call Distilling Co or a Liberty Call Distilling Co
        authorized representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow
        limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to
        you.
        <br /> <br />
        5. Accuracy of materials
        <br /> <br />
        The materials appearing on Liberty Call Distilling Co's website could
        include technical, typographical, or photographic errors. Liberty Call
        Distilling Co does not warrant that any of the materials on its website
        are accurate, complete or current. Liberty Call Distilling Co may make
        changes to the materials contained on its website at any time without
        notice. However Liberty Call Distilling Co does not make any commitment
        to update the materials.
        <br /> <br />
        6. Links
        <br /> <br />
        Liberty Call Distilling Co has not reviewed all of the sites linked to
        its website and is not responsible for the contents of any such linked
        site. The inclusion of any link does not imply endorsement by Liberty
        Call Distilling Co of the site. Use of any such linked website is at the
        user's own risk.
        <br /> <br />
        7. Modifications
        <br /> <br />
        Liberty Call Distilling Co may revise these terms of service for its
        website at any time without notice. By using this website you are
        agreeing to be bound by the then current version of these terms of
        service.
        <br /> <br />
        8. Governing Law
        <br /> <br />
        These terms and conditions are governed by and construed in accordance
        with the laws of California and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
        <br /> <br />
        Generated by GetTerms.io
      </p>
    </FullScreenModalContent>
  </Modal>
);

export default TermsAndConditions;
