import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundSection from './Background';

const DinnerOne = ({
  className,
  children,
  overlayColor,
  overlayOpacity,
  fixed,
}) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "dinnerOne.jpg" }) {
          childImageSharp {
            fluid(quality: 70, maxWidth: 2800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundSection
          className={className}
          imageData={imageData}
          children={children}
          overlayColor={overlayColor}
          overlayOpacity={overlayOpacity}
          fixed={fixed}
        />
      );
    }}
  />
);

export default DinnerOne;
